export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MS_CLIENT_ID, // Azure AD에서 생성한 애플리케이션의 클라이언트 ID
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MS_TENANT}`, // 지원 테넌트
      redirectUri: process.env.REACT_APP_MS_REDIRECT_URL // 애플리케이션의 리디렉션 URI
    },
    cache: {
      cacheLocation: "sessionStorage", // 인증 정보를 저장할 위치
      storeAuthStateInCookie: false // 브라우저가 쿠키를 사용하도록 설정
    }
  };
  
export const loginRequest = {
    scopes: ["User.Read"] // 사용자 프로필을 읽기 위한 기본 권한
};