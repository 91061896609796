import { useEffect, useState } from "react";
import { Accordion, Button, Nav, NavLink } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components"

const BtnToggle = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    border: 1px solid rgba(231, 234, 243, 1);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background-color: #fff;
    flex-shrink: 0;
    transform: translate(50%, 15px);
    z-index: 1;
    cursor: pointer;
    transition: transform 0.3s;
    .bi-arrow-bar-left {
        transition: transform 0.3s;

        &.show-menu {
            display: none;
        }
    }
`;

const NavWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100vh;

    .side-bar-nav {
        flex-direction: column;
        position: relative;
        padding-left: 10px;
        margin-top: 5px;
        gap: 5px 0;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 3px;
            background-color: rgba(231, 234, 243, 0.7);
            left: 0px;
        }

        a {
            display: block;
            width: 100%;
            padding: 8px 10px;
            border-radius: 10px;
            color: #eee;
            text-decoration: none;
            font-weight: 500;
            font-size: 14px;

            &.active,
            &:hover {
                background-color: rgba(189, 197, 209, 0.2);
            }

            i {
                font-size: 1.2rem;
                margin-right: 8px;
            }
        }
    }
`;

const BottomMenuWrap = styled.div`
    padding: 20px;
    border-top: 1px solid rgba(189, 197, 209, 0.2);
    width: 100%;
    white-space: nowrap;
}
`;

const Wrap = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 250px;
    flex-shrink: 0;
    background: #161717;
    border-right: 1px solid rgba(231, 234, 243, 0.7);
    transition: width 0.3s;

    &.hide {
        width: 100px;
        min-width: 100px;

        .category {
            // border-top: 1px solid rgba(231, 234, 243, 0.7);
            > span {
                opacity: 0;
            }
        }

        ${NavWrap} {
            width: 100%;
        }

        .nav-link {
            text-align: center;

            span {
                display: none;
            }
        }
        
        .accordion {
            overflow: visible;
            .accordion-item {
                position: relative;
                .title {
                    display: none;
                }

                .accordion-header {

                    .accordion-button {
                        &::after {
                            opacity: 0;
                            margin: 0;
                            width: 0;
                        }
                    }
                    &:hover {
                        & + .accordion-collapse {
                            display: block !important;
                        }
                    }
                }
                .accordion-collapse {
                    display: none;
                    transition: none;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    width: 180px;
                    z-index: 1;
                    
                    &:hover {
                        display: block;
                    }
                    
                    .accordion-body {
                        padding: 10px;
                        transform: translateX(30px);
                        background-color: #fff;
                        box-shadow: 0 .6125rem 2.5rem .6125rem rgba(140,152,164,.175);
                        border-radius: 10px;
                    }

                    .side-bar-nav {
                        &::before {
                            display: none;
                        }
                        padding-left: 0;
                        margin-top: 0;
                    }
                }
            }
        }

        ${BottomMenuWrap} {
            .text {
                display: none;
            }
        }
        ${BtnToggle} {
            .bi-arrow-bar-left {
                transform: rotate(180deg);
            }

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }
    }

    .accordion {
        overflow: auto;
        flex: 1 1;
        height: 100%;
        padding: 1.25rem;

        &::-webkit-scrollbar {
            width: 0.6125rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(189, 197, 209, 0.6);
        }
        * {
            border: none;
        }

        > a {
            display: block;
            border-radius: 10px;
        }

        .accordion-header {
            border-radius: 10px;

            &:hover {
                background-color: rgba(189, 197, 209, 0.2);
            }
        }
        .single.accordion-header {
            .accordion-button {
                &::after {
                    opacity: 0;
                }
            }
        }

        .accordion-button {
            position: relative;
            box-shadow: none;
            background-color: transparent;
            color: #000;

            > a {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                color: #000;
                padding: inherit;

                &.active {
                    color: #377dff;
                }
            }

            &:not(.collapsed) {
                color: #377dff;

                &::after {
                    transform: rotate(180deg);
                }
            }

            &::after {
                background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
            }
        }
        .accordion-item {
            background-color: inherit;

            .title {
                white-space: nowrap;
            }
        }
    }
`;

const NavSideBar = () => {
    const [isHide, setIsHide] = useState(parseInt(window.localStorage.getItem("nav_toggle")));
    const [activeKey, setActiveKey] = useState();
    const navigate = useNavigate();



    useEffect(() => {
        window.localStorage.setItem("nav_toggle", isHide);
    }, [isHide]);

    useEffect(() => {
        window.localStorage.setItem("nav_toggle", isHide);
    }, []);
    
    return (
        <div className="position-relative" style={{ zIndex: 100 }}>
            <Wrap className={isHide ? "hide" : ""}>
                <BtnToggle
                    onClick={() => {
                        if (isHide) {
                            setIsHide(0);
                        } else {
                            setIsHide(1);
                        }
                    }}
                >
                    <div className="bi-arrow-bar-left"></div>
                </BtnToggle>
                <NavWrap>
                        <div className="dropdown-header mt-4 category">
                            <span>
                                Monit.
                            </span>
                        </div>

                        <Nav className="side-bar-nav" 
                            onSelect={(selectKey, e) => {
                                let href = "";
                                if(e.target.tagName === 'A') {
                                    href = e.target.getAttribute("to");
                                }else {
                                    href = e.target.parentElement.getAttribute("to");
                                }
                                navigate(href);
                        }}>
                            {
                            <Nav.Item>
                                <Nav.Link eventKey={"/dashboard"} to={"/dashboard"}>
                                    <i className="bi bi-bar-chart"></i><span>Dashboard</span>
                                </Nav.Link>
                                <Nav.Link eventKey={"/monit"} to={"/monit"}>
                                    <i className="bi bi-display"></i><span>Monitoring</span>
                                </Nav.Link>
                            </Nav.Item>
                            }
                        </Nav>
                </NavWrap>
                {/* <BottomMenuWrap>
                    <Button className="w-100" variant="white">버튼 영역</Button>
                </BottomMenuWrap> */}
            </Wrap>
        </div>
    );
}

export default NavSideBar;