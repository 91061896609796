import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';

const ErrorTitle = styled.span`
    color: #B22222;
    font-weight: 700;
`

const ErrorTime = styled.span`
    float: right;
`

const ProjectDetail = () => {
	const { projectId } = useParams();
	const [project, setProject] = useState(null);
	const [errors, setErrors] = useState([]);
	const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
	const [endDate, setEndDate] = useState(new Date());

    const fetchProject = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/projects/detail/${projectId}`
            );
            setProject(response.data.data.info);
            setErrors(response.data.data.errors);
        } catch (error) {
            console.error(
                "프로젝트를 가져오는 중 문제가 발생했습니다.",
                error
            );
        }
    };
	useEffect(() => {
		fetchProject();
	}, [projectId]);

	return (
		<Container className="project-detail">
			{project && (
				<>
					<h1>{project.name}</h1>
					<h3>ID: {project.id}</h3>
					<Form className="detail-date mt-4">
                        <Form.Group controlId="startDate">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control"
                            />
                        </Form.Group>
                        <div>~</div>
                        <Form.Group controlId="endDate">
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                className="form-control"
                            />
                        </Form.Group>
                        <Button variant="dark" className="mt-3" onClick={() => fetchProject()}>
                            조회
                        </Button>
					</Form>
					<Row className="mt-4">
						{errors.length > 1 ? errors.map((error) => (
							<Col key={error.id} sm={12} md={6} lg={12} className="mb-4">
								<Card>
									<Card.Header>
                                            <ErrorTitle>
                                                {error.err_msg}
                                            </ErrorTitle>
										<ErrorTime>
											{new Date(error.register_time).toLocaleString()}
										</ErrorTime>
									</Card.Header>
									<Card.Body>
										<Card.Text>
											Error Type: {error.err_type}
										</Card.Text>
										<Card.Text>
                                            Error Code: {error.err_code}
										</Card.Text>
										<Card.Text>
										    Error Stack: {`${error.err_file} (Line: ${error.err_line})`}
										</Card.Text>
									</Card.Body>
								</Card>
							</Col>
						)) : <Col sm={12} md={6} lg={12} className="mb-4">발생한 에러가 없습니다.</Col>}
					</Row>
				</>
			)}
		</Container>
	);
};

export default ProjectDetail;
